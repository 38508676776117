import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import Layout from '../../components/Layout'
// import {
//   StaticContainer,
// } from '../styles/static.js'
import PackingSizes from '../../components/PackingSizes'
import StaticHeader from '../../components/StaticHeader'
import SEO from '../../components/seo'

const StyledExternalLink = styled.a`
  text-decoration: none;
`

const TitleHeading = styled.div`
  font-size: 34px;
  font-weight: 500;
  line-height: 1.5;
  color: #212121;
  margin-top: 32px;
  font-weight: 700;
  color: rgb(var(--dark-blue-grey));

  @media (max-width: 1025px) {
    line-height: 1.3;
    font-size: 30px;
  }
`

const Heading = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 2.4;
  color: #212121;
  margin-top: 32px;
  color: rgb(var(--dark-blue-grey));

  @media (max-width: 1025px) {
    line-height: 1.3;
    font-size: 22px;
    margin-bottom: 20px;
  }
`
const Body = styled.div`
  color: #212121;
  font-family: Objectivity;
  font-size: 16px;
  line-height: 23px;
  margin: 16px 13px;
  width: calc(100% - 26px);

  li {
    padding: auto !important;
    margin: auto;
  }
  ul {
    padding-left: 40px;
    margin: auto;
  }
`
const StaticContainer = styled.section`
  margin-top: 16px;
  @media (max-width: 1025px) {
    margin-top: 0px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
  background: white;
`
const TextContaner = styled.div`
  width: calc(100% - 26px);
  max-width: 800px;
`

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.gatsbyImageData
            ?.srcWebp
        }
      />
      <GatsbyImage
        image={data.NORMAL2.childImageSharp.gatsbyImageData}
        style={{ width: '100%', maxHeight: '50vh' }}
        objectFit="cover"
      />
      <StaticContainer>
        <TextContaner>
          <Body>
            <TitleHeading>
              {' '}
              3 simple ways to make your next trip memorable, for all the
              sexiest reasons{' '}
            </TitleHeading>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Holiday sex is some of the most memorable and exciting sex
                you&rsquo;ll have. That&rsquo;s a big call, I know, but I
                can&rsquo;t tell you how many couples have pulled their sex life
                out of the relational rutt after a holiday. A holiday gives you
                space from the everyday grind: you&rsquo;re out of your
                day-to-day, in a new environment, doing something fun with your
                partner, there&rsquo;s less stress, deadlines and to-do lists.
                Holidays offer an opportunity to be present and reconnect with
                your partner, all of this creating a rocking context for
                desire.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Now you may be making assumptions about the &lsquo;type&rsquo;
                of holiday that promises the-best-sex-of-your-life, cue: king
                bed decorated with rose petals, infinity pools and couples
                massages, and whilst all these things are certainly there to
                enhance an experience, there are a few simple things you can
                apply to any holiday - from camping to resort - there&rsquo;s a
                lot you can do. Here are three simple tips for your next holiday
                with your partner.
              </span>
            </p>
            <p>&nbsp;</p>
            <Heading>Create your context for desire</Heading>
            <p>
              <span style={{ fontWeight: 400 }}>
                This can actually be done in preparation for your holiday, a
                little vacation foreplay if you will. While you&rsquo;re booking
                your trip, open up the conversation and ask each other, what
                turns you on (ie what are your accelerators), what turns you off
                (ie what are your brakes) and what can you both do to create a
                context for desire on this trip? Get a sense of how you can
                actively include some of these accelerators and intentionally
                remove some of the brakes while you&rsquo;re on holiday. A few
                common breaks are stress, bickering with each other, a messy
                room, not feeling present, having to carry the mental load,
                certain smells, feelings or environments - it&rsquo;s a really
                individual thing - so get curious. Take some time to reflect on
                it, maybe even write a list and share your brakes and
                accelerators with one another so you can be more aware of what
                turns you on and off. I developed an{' '}
              </span>
              <a
                target="_blank"
                href="https://itsnormal.com/products/how-to-have-sex"
              >
                <span
                  style={{
                    fontWeight: 400,
                    textDecoration: 'underline',
                  }}
                >
                  online video course{' '}
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>
                with NORMAL that dives deep into the world of human desire - if
                you&rsquo;re in need of expert-led support I&rsquo;d recommend
                going through this prior to the trip, best part: it&rsquo;s now
                free!&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <GatsbyImage
              image={data.NORMAL1.childImageSharp.gatsbyImageData}
              style={{ width: '100%', maxHeight: '40vh' }}
              objectFit="cover"
            />
            <p>&nbsp;</p>
            <Heading>Ditch the routine</Heading>
            <p>
              <span style={{ fontWeight: 400 }}>
                Once you have the schedule and key dates booked in, allow for
                time to be spontaneous on your trip - nothing says holiday quite
                like the sentence{' '}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  &lsquo;so what do you want to do today?&rsquo;{' '}
                </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                When I work with couples in session, they will identify that
                they&rsquo;re sex life is lack lustre becasue they have to
                prioritise work, life, house commitments or they&rsquo;re so
                busy that sex is the furthest thing from their mind. A holiday
                is the perfect antidote to the
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  &lsquo;I must be busy&rsquo;
                </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                {' '}
                way of living. Set back the alarm clock, eat lunch whenever you
                want it, allow for room to do whatever feels good in the moment.
                The same can be said for your sex routine! Whilst it&rsquo;s
                important and valuable to know what you like in bed, try
                exploring a sexual experience beyond the routine and redefine
                sex! Oral in the shower, a sensual massage after lunch, a
                quickie before your dinner booking, sensation play, a steamy
                kiss in public, or try that new &lsquo;thing&rsquo; you&rsquo;ve
                always been curious about!
              </span>
            </p>
            <p>&nbsp;</p>
            <Heading>Prep your July luggage</Heading>
            <p>
              <span style={{ fontWeight: 400 }}>
                Lube never goes on vacation - pack all the goodies you need for
                a good time. I&rsquo;m talking toys, chargers, sensory items,
                blindfolds, the world is your aphrodisiac inducing oyster. My
                clients will often tell me that they get new toys or accessories
                in the lead up to a holiday as a new setting is the perfect
                opportunity to release the inhibitions and do something
                thoughtful for their partner. And have no fear, all{' '}
              </span>
              <a target="_blank" href="https://itsnormal.com">
                <span
                  style={{
                    fontWeight: 400,
                    textDecoration: 'underline',
                  }}
                >
                  NORMAL toys
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>
                {' '}
                come with a carry bag, so you can keep your toys safe, discreet
                and tucked away in your{' '}
                <a href="/shop">
                  <span
                    style={{
                      fontWeight: 400,
                      textDecoration: 'underline',
                    }}
                  >
                    July luggage
                  </span>
                </a>{' '}
                while you travel!
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We all need a holiday right now, good on you for taking time to
                rest and recharge!
              </span>
            </p>
          </Body>
        </TextContaner>
      </StaticContainer>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  {
    SEO: cockpitPage(
      cannonical_url: { value: { eq: "/memorable-holiday-trip" } }
    ) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }
      TEST_banner_image {
        value {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630, layout: FIXED)
          }
        }
      }
    }
    NORMAL1: file(relativePath: { eq: "NORMAL/NORMAL-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    NORMAL2: file(relativePath: { eq: "NORMAL/NORMAL-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
